export function acreNameFromType(acreType) {
    if (!acreType) {
        return '';
    }

    if (acreType === 'StandardHalf') {
        return 'Standard';
    } else if (acreType === 'DoF') {
        return 'Defender of Freedom';
    } else if (acreType === 'GP') {
        return 'Grazing Pasture';
    }

    return acreType;
}
