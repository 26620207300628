<template>
    <div :class="bem()" v-if="modelValue">
        <vue-final-modal v-model="showModal">
            <acre-info :acre="acre" @close="showModal = false" />
        </vue-final-modal>
    </div>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal';
import AcreInfo from './AcreInfo.vue';

export default {
    name: 'acre-dialog',
    components: {
        VueFinalModal,
        AcreInfo,
    },
    props: {
        modelValue: Boolean,
        acre: { type: Object, default: null },
    },
    data() {
        return {
            showModal: this.modelValue,
        };
    },
    watch: {
        modelValue: {
            handler() {
                this.showModal = this.modelValue;
            },
            immediate: true,
        },
        showModal: {
            handler() {
                this.$emit('update:modelValue', this.showModal);
            },
            immediate: true,
        },
    },
};
</script>

<style lang="scss">
.acre-dialog {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .vfm__container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .vfm__content {
        position: relative;
        display: flex;
        flex-direction: column;
        max-height: 90%;
        margin: 0 1rem;
    }
    .modal__title {
        margin: 0 2rem 0 0;
        font-size: 1.5rem;
        font-weight: 700;
    }
    .modal__content {
        flex-grow: 1;
        overflow-y: auto;
    }
    .modal__action {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        padding: 1rem 0 0;
    }
    .modal__close {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
    }
}
</style>
