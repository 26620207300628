import { defineStore } from 'pinia';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { get } from '@/utils/api';

export const useMainStore = defineStore('main', {
    state: () => {
        return {
            selectedAcreId: null,
            zoomedAcreId: null,
            acres: {},
            acresArray: [],
            selectedAcre: null,
            searchResults: null,
            lastAcreUpdate: 0,
            editable: false,
        };
    },
    actions: {
        zoomAcre(acreId) {
            this.zoomedAcreId = acreId;

            this.clearSearch();
        },
        selectAcre(arceId) {
            this.selectedAcreId = arceId;

            if (this.selectedAcreId) {
                this.selectedAcre = this.acres[parseInt(this.selectedAcreId)];
            } else {
                this.selectedAcre = null;
            }

            this.clearSearch();
        },
        updateAcreRecord(acreUpdates) {
            const acre = this.acresArray.find((a) =>
                a.records.find((r) => r.id === acreUpdates.id)
            );

            if (acre) {
                const record = acre.records.find(
                    (r) => r.id === acreUpdates.id
                );

                Object.keys(acreUpdates).forEach((prop) => {
                    if (prop !== 'id') {
                        record[prop] = acreUpdates[prop];
                    }
                });

                this.lastAcreUpdate = Date.now();
            }
        },
        async loadAcres() {
            const response = await get(axios, '/api/acres');

            let { acres, readOnly } = response.data;

            this.acres = acres;
            this.editable = readOnly === false;

            const acresArray = [];

            Object.keys(this.acres).forEach((key) => {
                // if (this.acres[key].records.length > 1) {
                //     console.log(key);
                // }

                acresArray.push(this.acres[key]);
            });

            this.acresArray = acresArray;
        },
        clearSearch() {
            this.searchResults = null;
        },
        search(searchTerm) {
            if (!searchTerm) {
                this.searchResults = null;
                return;
            }

            searchTerm = searchTerm.toLowerCase();
            console.log(searchTerm);

            const acreNumber = parseInt(searchTerm);

            const foundAcres = this.acresArray.filter((a) => {
                if (
                    !Number.isNaN(acreNumber) &&
                    parseInt(a.acreNumber) == acreNumber
                ) {
                    return true;
                }

                let found = false;

                for (let i = 0; i < a.records.length; i++) {
                    const record = a.records[i];

                    found =
                        found ||
                        (record.supporterLastName &&
                            record.supporterLastName
                                .toLowerCase()
                                .indexOf(searchTerm) !== -1) ||
                        (record.supporterFullName &&
                            record.supporterFullName
                                .toLowerCase()
                                .indexOf(searchTerm) !== -1);
                }
                return found;
            });

            const results = [];

            foundAcres.forEach((acre) => {
                acre.records.forEach((r) => {
                    results.push({ ...r, uuid: uuidv4() });
                });
            });

            this.searchResults = results;
        },
    },
});
