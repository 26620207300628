<template>
    <div :class="bem()">
        <div v-show="!editMode">
            <div
                :class="
                    bem('top-block', {
                        [`${acreTypeNameClass.toLowerCase()}-acre`]: true,
                    })
                "
            >
                <div
                    :class="
                        bem('acre-number', {
                            [`${acreTypeNameClass.toLowerCase()}`]: true,
                        })
                    "
                >
                    <div>Acre {{ acre?.acreNumber }}</div>
                </div>
                <button
                    :class="bem('close-button')"
                    @click="$emit('close')"
                ></button>
                <div :class="bem('acre-type')">{{ acreTypeName }} Acre</div>
            </div>
            <div
                :class="
                    bem('middle-block', {
                        'multi-sponsor': sponsors.length > 1,
                    })
                "
            >
                <div
                    v-for="(s, i) in sponsors"
                    :key="i"
                    :class="
                        bem('sponsor-info', {
                            active: sponsorIndex === i,
                        })
                    "
                >
                    <h2 :class="bem('last-name')">{{ s.supporterLastName }}</h2>
                    <h2 :class="bem('full-name')">{{ s.supporterFullName }}</h2>
                    <h2 :class="bem('sponsor-amount')">
                        {{ formatPrice(s.sponsorshipLevel) }}
                    </h2>
                </div>

                <div
                    :class="bem('sponsor-selector')"
                    v-if="sponsors.length > 1"
                >
                    <button
                        :class="
                            bem('sponsor-toggle-button', {
                                selected: sponsorIndex === i,
                            })
                        "
                        v-for="(s, i) in sponsors"
                        :key="i"
                        @click="sponsorIndex = i"
                    >
                        {{ String.fromCharCode(65 + i) }}
                    </button>
                </div>
            </div>
            <div :class="bem('bottom-block')">
                <div
                    v-for="(s, i) in sponsors"
                    :key="i"
                    :class="
                        bem('sponsor-extra', { active: sponsorIndex === i })
                    "
                >
                    <div>
                        <div :class="bem('notes')">
                            <strong>Notes:</strong>
                            <span>{{ s.notes }}</span>
                        </div>
                        <div>
                            <strong>Plaque Status:</strong>
                            {{ s.plaqueStatus }}
                        </div>
                    </div>
                </div>
                <button
                    v-if="editable"
                    :class="bem('edit-button')"
                    @click="editMode = true"
                ></button>
            </div>
        </div>
        <div v-show="editMode" :class="bem('edit-view')">
            <loading :active="operationInProgess" :can-cancel="false" />
            <div :class="bem('top-block')">
                <div
                    :class="
                        bem('acre-number', {
                            [`${acreTypeNameClass.toLowerCase()}`]: true,
                        })
                    "
                >
                    <div>Acre {{ acre?.acreNumber }}</div>
                </div>
                <button
                    :class="bem('close-button')"
                    @click="$emit('close')"
                ></button>
            </div>
            <div
                :class="
                    bem('acre-form', { 'multi-sponsor': sponsors.length > 1 })
                "
            >
                <label for="Acre-Type-2" class="field-label">Acre Type</label
                ><select
                    class="select-field w-select"
                    v-model="editableAcre.acreType"
                >
                    <option value="Standard">Standard Acre</option>
                    <option value="GP">Grazing Pasture Acre</option>
                    <option value="StandardHalf">Standard Half Acre</option>
                    <option value="DoF">Defender of Freedom Acre</option>
                    <option value="Special">Special Acre</option>
                </select>
                <label for="Supporter-Last-Name" class="field-label"
                    >Supporter Last Name</label
                >
                <input
                    v-model="editableAcre.supporterLastName"
                    type="text"
                    class="input-text-field edit w-input"
                    maxlength="256"
                    placeholder="Last Name"
                />
                <label for="Supporter-Full-Name" class="field-label"
                    >Supporter Full Name</label
                >
                <input
                    v-model="editableAcre.supporterFullName"
                    type="text"
                    class="input-text-field edit w-input"
                    maxlength="256"
                    placeholder="Full Name"
                    required
                />
                <label for="CRM-Link-3" class="field-label">CRM Link</label>
                <input
                    v-model="editableAcre.supporterCrmLink"
                    type="text"
                    class="input-text-field edit w-input"
                    maxlength="256"
                    placeholder="https://"
                    id="CRM-Link"
                    required
                /><label for="Sponsorship-Level" class="field-label"
                    >Sponsorship Level</label
                ><input
                    ref="sponsorshipLevelEl"
                    v-model="editableAcre.sponsorshipLevel"
                    type="text"
                    class="input-text-field edit w-input"
                    maxlength="256"
                    placeholder="$"
                    required
                />

                <label for="Plaque-Status" class="field-label"
                    >Plaque Status</label
                ><select
                    class="select-field w-select"
                    v-model="editableAcre.plaqueStatus"
                >
                    <option value="">Select one...</option>
                    <option value="Standard">Standard</option>
                    <option value="Special">Special</option>
                    <option value="Creating Language">Creating Language</option>
                    <option value="Reviewing Language">
                        Reviewing Language
                    </option>
                    <option value="Installed">Installed</option>
                </select>
                <label for="Acre-Number-Position-2" class="field-label"
                    >Acre # Position</label
                ><select
                    id="Acre-Number-Position"
                    name="Acre-Number-Position"
                    data-name="Acre Number Position"
                    class="select-field w-select"
                    v-model="editableAcre.numPos"
                >
                    <option value="UL">Top Left</option>
                    <option value="UM">Top Center</option>
                    <option value="UR">Top Right</option>
                    <option value="CL">Middle Left</option>
                    <option value="CC">Middle Center</option>
                    <option value="CR">Middle Right</option>
                    <option value="LL">Bottom Left</option>
                    <option value="LM">Bottom Center</option>
                    <option value="LR">Bottom Right</option>
                </select>
                <label for="Notes" class="field-label">Notes</label>
                <textarea
                    v-model="editableAcre.notes"
                    maxlength="5000"
                    placeholder="optional notes"
                    class="input-text-multiline w-input"
                >
                </textarea>

                <div
                    :class="bem('sponsor-selector')"
                    v-if="sponsors.length > 1"
                >
                    <button
                        :class="
                            bem('sponsor-toggle-button', {
                                selected: sponsorIndex === i,
                            })
                        "
                        v-for="(s, i) in sponsors"
                        :key="i"
                        @click="sponsorIndex = i"
                    >
                        {{ String.fromCharCode(65 + i) }}
                    </button>
                </div>
            </div>
            <div :class="bem('button-block')">
                <button class="cancel-button" @click="$emit('close')">
                    Cancel
                </button>
                <button @click="saveAcre">Save</button>
            </div>
        </div>
    </div>
</template>

<script>
import { post } from '@/utils/api';
import { mapActions, mapState } from 'pinia';
import { useMainStore } from '@/stores/mainStore.js';
import { acreNameFromType } from '@/utils/helpers';
import AutoNumeric from 'autonumeric';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name: 'acre-info',
    components: {
        Loading,
    },
    props: {
        acre: { type: Object, default: null },
    },
    data() {
        return { sponsorIndex: 0, editMode: false, operationInProgess: false };
    },
    watch: {
        acre: {
            handler() {},
            immediate: true,
        },
    },
    mounted() {
        new AutoNumeric(this.$refs.sponsorshipLevelEl, { currencySymbol: '$' });
    },
    computed: {
        ...mapState(useMainStore, ['editable']),
        sponsors() {
            if (!this.acre) {
                return [];
            }

            const { records } = this.acre;
            const sponsors = [];

            records.forEach((sponsor) => {
                const {
                    sponsorshipLevel,
                    sponsorshipStatus,
                    supporterCrmLink,
                    supporterFullName,
                    supporterLastName,
                    notes,
                    plaqueStatus,
                } = sponsor;

                sponsors.push({
                    sponsorshipLevel,
                    sponsorshipStatus,
                    supporterCrmLink,
                    supporterFullName,
                    supporterLastName:
                        sponsorshipStatus === 'Available'
                            ? 'Available'
                            : supporterLastName,
                    notes,
                    plaqueStatus,
                });
            });

            return sponsors;
        },
        acreTypeName() {
            if (!this.acre) {
                return '';
            }

            return acreNameFromType(this.acre.acreType);
        },
        acreTypeNameClass() {
            return this.acreTypeName.replace(new RegExp(' ', 'g'), '');
        },
        editableAcre() {
            if (this.acre) {
                const { acreType, records } = this.acre;
                const {
                    id,
                    supporterLastName,
                    supporterFullName,
                    supporterCrmLink,
                    sponsorshipLevel,
                    plaqueStatus,
                    notes,
                    numPos,
                } = records[this.sponsorIndex];

                return {
                    id,
                    acreType,
                    supporterLastName,
                    supporterFullName,
                    supporterCrmLink,
                    sponsorshipLevel,
                    plaqueStatus,
                    notes,
                    numPos,
                };
            } else {
                return {};
            }
        },
    },
    methods: {
        ...mapActions(useMainStore, ['updateAcreRecord']),
        async saveAcre() {
            this.operationInProgess = true;

            this.editableAcre.sponsorshipLevel = parseFloat(
                this.editableAcre.sponsorshipLevel
            );

            // if a name is included set status to sponsored, else available
            if (
                this.editableAcre.supporterLastName &&
                this.editableAcre.supporterLastName.trim().length > 0
            ) {
                this.editableAcre.sponsorshipStatus = 'Sponsored';
            } else if (!this.editableAcre.supporterFullName) {
                // both last name and full name are empty
                this.editableAcre.sponsorshipStatus = 'Available';
            }

            await post(
                this.$http,
                `/api/acres/${this.editableAcre.id}/update`,
                this.editableAcre
            );

            this.operationInProgess = false;

            await this.updateAcreRecord(this.editableAcre);

            this.$emit('close');
        },
        formatPrice(number) {
            if (!number) {
                return '';
            }
            return (
                '$' +
                number.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
            );
        },
    },
};
</script>

<style lang="scss">
@import '../styles/main.scss';

.acre-info {
    width: 360px;
    border-radius: 12px;
    box-shadow: 3px 3px 7px 2px rgb(0 0 0 / 40%);
    background: white;
    position: relative;

    &__button-block {
        position: relative;
        display: flex;
        min-height: 50px;
        padding: 0px 20px 20px;
        justify-content: center;
        align-items: center;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        background-color: #e5e1df;

        button {
            border-style: solid;
            border-width: 1px;
            border-color: #302826;
            background-color: #302826;
            -webkit-transition: all 200ms ease;
            transition: all 200ms ease;
            font-family: Lato, sans-serif;
            color: #ccbeb7;
            font-size: 12px;
            line-height: 18px;
            font-weight: 300;
            text-align: center;
            letter-spacing: 0.5px;
            text-transform: none;
            display: inline-block;
            padding: 9px 15px;
            text-decoration: none;
            border-radius: 0;
            flex: 1;

            &:hover {
                border-style: solid;
                border-width: 1px;
                border-color: #bbaea3;
                background-color: #3d3330;
                color: #fff;
            }

            &.cancel-button {
                margin-right: 20px;
                border-color: #bbaea3;
                background-color: transparent;
                color: #3d3330;

                &:hover {
                    background-color: #3d3330;
                    color: #fff;
                }
            }
        }
    }

    &__edit-view {
        background-color: #e5e1df;
        border-radius: 10px;
        position: relative;

        .acre-info__top-block {
            min-height: 30px;
        }
    }

    &__acre-form {
        margin: 0 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        text-align: left;

        &--multi-sponsor {
            padding-bottom: 35px;
        }
    }

    &__sponsor-info {
        display: none;

        &--active {
            display: block;
        }
    }

    &__sponsor-amount {
        font-weight: 400;
        margin: 10px 0;
    }

    &__notes {
        max-height: 35px;
        overflow: hidden;
        max-width: 90%;
        line-clamp: 2;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    &__sponsor-selector {
        position: absolute;
        bottom: 5px;
        width: 100%;
        left: 0;
        display: flex;
        grid-column-gap: 8px;
        text-align: center;
        justify-content: center;
    }

    &__sponsor-toggle-button {
        display: flex;
        width: 30px;
        height: 30px;
        padding: 5px;
        align-items: center;
        border-radius: 20px;
        justify-content: center;
        transition: background-color 300ms;

        &--selected {
            background-color: #f5f3f3;
        }

        &:hover {
            background-color: #f5f3f3;
        }
    }

    &__top-block {
        position: relative;
        display: flex;
        min-height: 60px;
        padding-bottom: 5px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-size: cover;

        &--standard-acre {
            background-image: url(../assets/images/title-bg-standard-acre.png);

            .acre-info__acre-number {
                background-color: $standard-color;
            }
        }

        &--special-acre {
            background-image: url(../assets/images/title-bg-special-acre.png);

            .acre-info__acre-number {
                background-color: $special-color;
            }
        }

        &--grazingpasture-acre {
            background-image: url(../assets/images/title-bg-grazing-acre.png);

            .acre-info__acre-number {
                background-color: $gp-color;
            }
        }

        &--defenderoffreedom-acre {
            background-image: url(../assets/images/title-bg-defender-of-freedom.png);

            .acre-info__acre-number {
                background-color: $dof-color;
            }
        }
    }

    &__acre-number {
        padding: 6px 12px;
        font-family: Lato, sans-serif;
        color: #fff;
        font-size: 11px;
        font-weight: 700;
        text-align: center;
        letter-spacing: 3px;
        text-transform: uppercase;

        &--standard {
            background-color: $standard-color;
        }

        &--special {
            background-color: $special-color;
        }

        &--grazingpasture {
            background-color: $gp-color;
        }

        &--defenderoffreedom {
            background-color: $dof-color;
        }
    }

    &__close-button {
        position: absolute;
        left: auto;
        top: 0%;
        right: 0%;
        bottom: auto;
        padding: 6px;
        width: 24px;
        height: 24px;
        opacity: 0.5;
        -webkit-transition: opacity 200ms ease;
        transition: opacity 200ms ease;
        background-image: url(../assets/images/close.svg);
        background-size: 12px 12px;
        background-position: center;
        background-repeat: no-repeat;

        &:hover {
            opacity: 1;
        }
    }

    &__acre-type {
        margin-top: 10px;
        clear: both;
        color: #fff;
        font-size: 24px;
        font-style: italic;
        letter-spacing: 1.5px;
    }

    &__middle-block {
        position: relative;
        display: flex;
        // width: 100%;
        // min-height: 160px;
        min-height: 130px;
        padding: 20px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        background-color: #e5e1df;
        text-align: center;

        &--multi-sponsor {
            min-height: 160px;
        }
    }

    &__last-name {
        margin-top: 0px;
        margin-bottom: 10px;
        min-height: 36px;
        padding: 5px 15px;
        border-style: none;
        border-width: 1px;
        border-color: #fff;
        color: #1a1d20;
        font-size: 32px;
        line-height: 36px;
        font-weight: 400;
    }

    &__full-name {
        margin-top: 0px;
        margin-bottom: 0px;
        padding-top: 10px;
        padding-bottom: 5px;
        min-height: 19px;
        border-top: 1px solid #bbaea3;
        font-family: Lato, sans-serif;
        color: #1a1d20;
        font-size: 14px;
        line-height: 19px;
        font-weight: 300;
        letter-spacing: 0.5px;
    }

    &__amount {
        display: block;
        margin-top: 0px;
        margin-bottom: 0px;
        padding: 5px 15px;
        color: #1a1d20;
        font-size: 24px;
        font-weight: 400;
    }

    &__bottom-block {
        position: relative;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        min-height: 50px;
        padding: 5px 10px;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        background-color: #d9d4d1;
    }

    &__sponsor-extra {
        font-family: Lato, sans-serif;
        color: #1a1d20;
        font-size: 12px;
        line-height: 18px;
        font-weight: 300;
        position: absolute;
        opacity: 0;
        text-align: left;
        width: 100%;

        &--active {
            opacity: 1;
        }
    }

    &__edit-button {
        position: absolute;
        left: auto;
        top: auto;
        right: 0%;
        bottom: auto;
        width: 34px;
        height: 34px;
        padding: 6px;
        opacity: 0.5;
        -webkit-transition: opacity 200ms ease;
        transition: opacity 200ms ease;
        background-image: url(../assets/images/edit.svg);
        background-size: 20px 20px;
        background-position: center;
        background-repeat: no-repeat;
    }
}
</style>
