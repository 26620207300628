export function checkBounds({ x, y, minX, minY, maxX, maxY }) {
    let xDiff = 0;
    let yDiff = 0;

    if (minX !== undefined && x < minX) {
        xDiff = minX - x;
    } else if (maxX !== undefined && x > maxX) {
        xDiff = maxX - x;
    }

    if (minY !== undefined && y < minY) {
        yDiff = minY - y;
    } else if (maxY !== undefined && y > maxY) {
        yDiff = maxY - y;
    }

    let restrictedX;
    let restrictedY;

    if (xDiff !== 0) {
        restrictedX = xDiff > 0 ? minX : maxX;
    }
    if (yDiff !== 0) {
        restrictedY = yDiff > 0 ? minY : maxY;
    }

    return {
        xDiff,
        yDiff,
        restrictedX,
        restrictedY,
        inBounds: xDiff === 0 && yDiff === 0,
    };
}
