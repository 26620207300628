import { createApp } from 'vue';
import { createPinia } from 'pinia';
import axios from 'axios';
import VueAxios from 'vue-axios';
import App from './App.vue';
import router from './router';
import { bemMixin } from './mixins/bem';
import { GesturePlugin } from '@vueuse/gesture';

require('./mixins/bem');

createApp(App)
    .use(router)
    .use(createPinia())
    .use(VueAxios, axios)
    .use(GesturePlugin)
    .mixin(bemMixin)
    .mount('#app');
