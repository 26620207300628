<template>
    <header>
        <h1>Rancho del Cielo Sponsorship Map</h1>
        <button @click="logout">Logout</button>
    </header>
    <div class="page-container">
        <router-view />
    </div>
</template>

<script>
import { logout } from '@/utils/api';


export default {
    methods: {
        async logout() {
            await logout(this.$http);

            this.$router.push('/login');
        }
    }
}
</script>

<style lang="scss">
// @import url('https://use.typekit.net/jgl2rdb.css');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

@import './styles/main.scss';

#app {
    font-family: $fontface;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin: 0;
    padding: 0;
    touch-action: none;
}

body {
    margin: 0;
    padding: 0;
    background: #c6b6a7;
    touch-action: none;
}

button {
    font-family: $fontface;
}

header {
    height: 50px;
    display: flex;
    align-items: center;
    background-color: #302826;
    box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 30%);
    text-align: center;

    h1 {
        color: white;
        display: inline;
        margin: 0 auto;
        color: #e5e1df;
        font-size: 24px;
        letter-spacing: 1px;
    }

    button {
        color: #e5e1df;
        text-decoration: underline;
        position: absolute;
        right: 20px;
    }
}

.page-container {
    position: absolute;
    top: 50px;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden; // todo: maybe just set this to scroll for panning?
}
</style>
