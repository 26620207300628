const bemOptions = {
    separators: {
        element: '__',
        modifier: '--',
        value: '-'
    },
    hyphenate: {
        block: true,
        element: true,
        modifier: true,
        state: true
    }
};

// Convert to kebab case (flag makes syntax easier with options)
function hyphenate(str, shouldHyphenate = true) {
    if (str) {
        return shouldHyphenate ? str.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase() : str;
    } else {
        return str;
    }
}

// Get block name
function getBlockName(component) {
    if (!component.$options._blockName) {
        let block = component.$options.name || component.$options._componentTag;
        if (block && block.indexOf('/') > -1) {
            block = block
                .split('/')
                .pop()
                .split('.')[0];
        }
        component.$options._blockName = block;
    }

    return component.$options._blockName;
}

// Calcualte the bem classes for a given block, element and set of modifiers
function getBemClasses(blockName, elementName, modifiers, options) {
    const block = hyphenate(blockName, options.hyphenate.block);
    const element = elementName
        ? options.separators.element + hyphenate(elementName, options.hyphenate.element)
        : '';
    const bem = block + element;

    if (!modifiers) {
        return bem;
    }

    let modClasses = Object.keys(modifiers)
        .map(key => {
            const val = modifiers[key];
            const mod = hyphenate(key, options.hyphenate.modifier);

            // toggle boolean modifiers
            if (typeof val === 'boolean') {
                return val ? bem + options.separators.modifier + mod : null;
            }

            // concatenate non-boolean modifiers if not falsy
            else {
                return val
                    ? bem +
                          options.separators.modifier +
                          mod +
                          options.separators.value +
                          hyphenate(val, options.hyphenate.state)
                    : null;
            }
        })
        .filter(val => !!val);

    // add bem class to beginning of list
    modClasses.unshift(bem);
    return modClasses.join(' ');
}

export const bemMixin = {
    beforeCreate() {
        // enable enum props validator

        const options = this.$options;
        const props = options.props || {};

        for (const prop of Object.keys(props)) {
            const descriptor = props[prop];
            if (Array.isArray(descriptor.enum)) {
                const nextValidator = descriptor.validator;
                descriptor.validator = value => {
                    if (value) {
                        let result =
                            descriptor.enum.indexOf(value) >= 0 &&
                            (nextValidator ? nextValidator.apply(this, arguments) : true);
                        if (!result) {
                            // debugger;
                        }
                        return result;
                    }
                    return true;
                };
            }
        }
    },

    methods: {
        /*
        Generates classes using BEM methodology.
            :class="bem()" ->  class="my-component"
            :class="bem('element')" ->  class="my-component__element"
            :class="bem({dark: true})" -> class="my-component my-component--dark"
            :class="bem({theme: 'dark'})" -> class="my-component--theme-dark"
            :class="bem('el', {dark: true})" -> class="my-component__el my-component__el--dark"
        */
        bem(arg1, arg2) {
            let element = typeof arg1 == 'string' ? arg1 : null;
            let modifiers = typeof arg1 == 'string' ? arg2 : arg1;
            return getBemClasses(getBlockName(this), element, modifiers, bemOptions);
        },

        /*
        Converts a number to a px string
            px(45) => '45px'
        */
        cssPx(val) {
            if (val) {
                return Math.round(val) + 'px';
            }
            return null;
        },

        /*
         Converts a number to a percentage string
            percent(0.45) => '45%'
        */
        cssPcnt(val) {
            if (val) {
                return val * 100 + '%';
            }
            return null;
        },

        /*
        Wrap a string in 'url' syntax for CSS
            url('image.png') => 'url("image.png")'
        */
        cssUrl(val) {
            if (val) {
                return `url("${val}")`;
            }
            return null;
        },

        // converts as tring like '3:2' to 1.5
        strToAspect(str) {
            if (str && typeof str == 'string') {
                let parts = str.split(':');
                if (parts.length == 2) {
                    return parseFloat(parts[0]) / parseFloat(parts[1]);
                }
                return parseFloat(str);
            }
            return null;
        }
    }
};
