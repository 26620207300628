
let API_BASE_URL = process.env.API_BASE_URL || '';

if (API_BASE_URL == 'undefined') {
    API_BASE_URL = '';
}

export async function get($http, url) {
    try {
        const response = await $http.get(`${API_BASE_URL}${url}`, {
            withCredentials: true,
        });

        return response;
    } catch (e) {
        if (e.response && e.response.status === 403) {
            window.location = '/login';
        } else {
            // report error to store
        }
    }
}

export async function post($http, url, data) {
    try {
        const response = await $http.post(`${API_BASE_URL}${url}`, data, {
            withCredentials: true,
        });

        return response;
    } catch (e) {
        if (e.response && e.response.status === 403) {
            window.location = '/login';
        } else {
            // report error to store
            console.error(e);
        }
    }
}

export async function login($http, password) {
    try {
        const response = await $http.post(
            `${API_BASE_URL}/auth`,
            {
                pass: password,
            },
            { withCredentials: true }
        );

        console.log(response);

        if (response.status === 200) {
            return true;
        }
    } catch (e) {
        return false;
    }

    return false;
}

export async function logout($http) {
    try {
        const response = await $http.post(
            `${API_BASE_URL}/auth/logout`,
            { withCredentials: true }
        );

        console.log(response);

        if (response.status === 200) {
            return true;
        }
    } catch (e) {
        return false;
    }

    return false;
}
