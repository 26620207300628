<template>
    <div :class="bem()" ref="outer">
        <div
            :class="bem('inner-container')"
            ref="inner"
            :style="{ opacity: loading ? 0 : 1 }"
        >
            <div
                :class="bem('fill-container')"
                ref="fillContainer"
                :style="{ transform: `scale(${fillScale})` }"
            >
                <zoom-container
                    :class="bem('zoom-container')"
                    ref="zoomContainer"
                    :animate="animateZoomChanges"
                    :scale="currentZoom"
                    :translate="zoomOffset"
                    :container-el="$refs.inner"
                    @updateTransform="onZoomChange"
                >
                    <img
                        :src="require('../assets/rrc-map-2.png')"
                        ref="mapImage"
                        :class="bem('map-image')"
                    />
                    <inline-svg
                        ref="mapSvg"
                        @loaded="mapLoaded"
                        @click="mapClick"
                        :src="require('../assets/fullMap.svg')"
                        :class="bem('acres-svg')"
                    />
                    <div
                        :class="bem('map-overlay')"
                        :style="{ transform: `scale(${overlayScale})` }"
                    >
                        <div
                            :class="
                                bem('acre-info', {
                                    [acre.acreType.toLowerCase()]: true,
                                    [acre.numPos]: true,
                                })
                            "
                            v-for="acre in acresInfo"
                            :key="acre.number"
                            :style="{
                                left: `${acre.position.x}px`,
                                top: `${acre.position.y}px`,
                                width: `${acre.position.width}px`,
                                height: `${acre.position.height}px`,
                            }"
                        >
                            <span
                                :style="{ opacity: showAcreNumbers ? 1 : 0 }"
                                >{{ acre.number }}</span
                            >
                        </div>
                    </div>
                    <!-- <div
                        :class="bem('zoom-center')"
                        :style="{
                            top: `${zoomCenter.y}px`,
                            left: `${zoomCenter.x}px`,
                        }"
                    ></div>
                    <div
                        :class="bem('map-center')"
                        :style="{
                            top: `${mapCenter.y}px`,
                            left: `${mapCenter.x}px`,
                        }"
                    ></div> -->
                </zoom-container>
            </div>
        </div>
        <div :class="bem('search-host')">
            <input
                type="text"
                placeholder="Acre # / Name"
                v-model="searchTerm"
            />
            <button class="search-icon control-button" />
        </div>
        <div :class="bem('controls-host')">
            <div :class="bem('zoom-controls')">
                <button
                    :class="bem('zoom-in-button')"
                    class="control-button"
                    @click="zoomIn"
                ></button>
                <button
                    :class="bem('zoom-out-button')"
                    class="control-button"
                    @click="zoomOut"
                ></button>
            </div>
            <div :class="bem('map-controls')">
                <button
                    :class="bem('zoom-reset-button')"
                    class="control-button"
                    @click="resetZoom"
                ></button>
                <button
                    :class="bem('download-button')"
                    class="control-button"
                    @click="downloadMap"
                ></button>
            </div>
        </div>
        <div
            v-if="searchResults && searchResults.length"
            :class="bem('search-results')"
        >
            <h2>Search Results</h2>
            <button
                :class="bem('search-close-button')"
                @click="closeSearch"
            ></button>
            <div
                :class="bem('search-results-grid')"
                ref="searchResultGrid"
                :style="{ height: searchGridHeight }"
            >
                <div ref="searchResultInner">
                    <div
                        :class="bem('search-result-row')"
                        v-for="result in searchResults"
                        :key="result.uuid"
                        role="button"
                        @click="selectSearchResult(result)"
                    >
                        <div :class="bem('search-last-name')">
                            {{ result.supporterLastName }}
                        </div>
                        <div :class="bem('search-acre-type')">
                            {{ acreNameFromType(result.acreType) }} Acre
                        </div>
                        <div
                            :class="
                                bem('search-acre-number', {
                                    [result.acreType.toLowerCase()]: true,
                                })
                            "
                        >
                            {{ parseInt(result.acreNumber) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'pinia';
import { mapState } from 'pinia';
import { useMainStore } from '../stores/mainStore.js';
import { acreNameFromType } from '@/utils/helpers';
import InlineSvg from 'vue-inline-svg';
import ZoomContainer from './ZoomContainer.vue';

export default {
    name: 'acres-map',
    components: {
        InlineSvg,
        ZoomContainer,
    },
    data() {
        return {
            isMapLoaded: false,
            acresInfo: [],
            overlayScale: 1,
            fillScale: 1,
            showAcreNumbers: true,
            loading: true,
            zoomTransform: 'scale(1)',
            mapCenter: { x: 0, y: 0 },
            zoomCenter: { x: 0, y: 0 },
            searchTerm: null,
            currentZoom: 1,
            zoomOffset: { x: 0, y: 0 },
            searchGridHeight: '',
            acreNameFromType,
            animateZoomChanges: true,
        };
    },
    async mounted() {
        this.fillMap();

        await this.loadAcres();

        window.addEventListener('resize', this.sizeElements);
    },
    computed: {
        ...mapState(useMainStore, [
            'selectedAcreId',
            'acres',
            'searchResults',
            'zoomedAcreId',
            'lastAcreUpdate',
        ]),
    },
    watch: {
        zoomedAcreId: {
            handler() {
                this.zoomedAcreIdChanged();
            },
            immediate: true,
        },
        acres() {
            this.loadAcreInfo();
        },
        searchTerm() {
            this.search(this.searchTerm);
        },
        searchResults() {
            this.$nextTick(() => {
                if (!this.$refs.searchResultInner) return;

                const innerHeight = this.$refs.inner.clientHeight;
                const gridHeight = this.$refs.searchResultInner.clientHeight;

                if (gridHeight > innerHeight / 2) {
                    this.searchGridHeight = `${innerHeight / 2}px`;
                } else {
                    this.searchGridHeight = undefined;
                }
            });
        },
        lastAcreUpdate() {
            this.$nextTick(() => {
                this.reloadAcreInfo();
            });
        },
    },
    methods: {
        ...mapActions(useMainStore, [
            'selectAcre',
            'loadAcres',
            'search',
            'clearSearch',
            'zoomAcre',
        ]),
        selectSearchResult(result) {
            this.zoomAcre(parseInt(result.acreNumber));
            this.clearSearchTerm();
        },
        async reloadAcreInfo() {
            await this.loadAcres();
        },
        closeSearch() {
            this.clearSearch();
            this.clearSearchTerm();
        },
        clearSearchTerm() {
            this.searchTerm = null;
        },
        onZoomChange(zoomChange) {
            if (zoomChange.operation !== 'wheel') {
                this.animateZoomChanges = false;
            }

            this.$nextTick(() => {
                this.currentZoom = zoomChange.scale;
                this.zoomOffset = zoomChange.translate;

                setTimeout(() => {
                    this.animateZoomChanges = true;
                }, 10);
            });
        },
        zoomedAcreIdChanged() {
            if (!this.$refs.mapSvg) {
                return;
            }

            if (this.zoomedAcreId) {
                const acreEl = document.querySelector(
                    `[data-acre-id="${this.zoomedAcreId}"]`
                );

                console.log('acre zoom');

                if (!acreEl) return;

                const elBB = acreEl.getBBox();
                const { x, y, width, height } = elBB;
                const elCenter = {
                    x: x * this.overlayScale + (width * this.overlayScale) / 2,
                    y: y * this.overlayScale + (height * this.overlayScale) / 2,
                };
                const { offsetWidth, offsetHeight } =
                    this.$refs.zoomContainer.$el;
                const zoomerCenter = {
                    x: offsetWidth / 2,
                    y: offsetHeight / 2,
                };

                const translateX = -(elCenter.x - zoomerCenter.x);
                const translateY = zoomerCenter.y - elCenter.y;

                // this.zoomCenter = elCenter;
                // this.mapCenter = zoomerCenter;

                this.applyZoom(5, translateX, translateY);
            } else {
                this.resetZoom();
            }

            const DEMOTED_ACRE_CLASS = 'demoted-acre';
            const ZOOMED_ACRE_CLASS = 'zoomed-acre';

            this.$refs.mapSvg.$el.childNodes.forEach((acreEl) => {
                acreEl.classList.remove(DEMOTED_ACRE_CLASS);
                acreEl.classList.remove(ZOOMED_ACRE_CLASS);

                if (this.zoomedAcreId) {
                    const className =
                        this.zoomedAcreId &&
                        this.zoomedAcreId != acreEl.dataset.acreId
                            ? DEMOTED_ACRE_CLASS
                            : ZOOMED_ACRE_CLASS;

                    acreEl.classList.add(className);
                }
            });
        },
        zoomIn() {
            this.applyZoom(Math.min(7, this.currentZoom + 1));
        },
        zoomOut() {
            this.applyZoom(Math.max(1, this.currentZoom - 1));
        },
        resetZoom() {
            this.clearSearch();

            if (this.zoomedAcreId) {
                this.zoomAcre(null);
            } else {
                this.zoomOffset = { x: 0, y: 0 };
                this.applyZoom(1);
            }
        },
        applyZoom(zoom, centerX, centerY) {
            if (zoom) {
                this.currentZoom = zoom;
            }

            if (centerX !== undefined && centerY !== undefined) {
                this.zoomCenter = { x: centerX, y: centerY };

                this.zoomOffset = { x: centerX, y: centerY };
            }

            // if (this.zoomCenter) {
            //     this.zoomTransform = `scale(${this.currentZoom}) translate(${this.zoomCenter.x}px, ${this.zoomCenter.y}px)`;
            // } else {
            //     this.zoomTransform = `scale(${this.currentZoom})`;
            // }
        },
        fillMap() {
            const { offsetHeight: containerHeight } = this.$refs.fillContainer;
            const { offsetHeight: mapHeight } = this.$refs.mapImage;

            if (mapHeight === 0) return;

            this.loading = false;

            if (mapHeight > containerHeight) {
                this.fillScale = containerHeight / mapHeight;
            } else {
                this.fillScale = 1;
            }
        },
        sizeElements() {
            if (this.isMapLoaded) {
                const { offsetWidth } = this.$refs.mapImage;

                const svgViewbox = this.$refs.mapSvg.$el
                    .getAttribute('viewBox')
                    .split(' ');

                this.overlayScale = offsetWidth / svgViewbox[2];

                this.fillMap();
            }
        },
        loadAcreInfo() {
            if (this.acres && this.isMapLoaded) {
                this.sizeElements();

                const info = [];

                this.$refs.mapSvg.$el.childNodes.forEach((acreEl) => {
                    const { acreId } = acreEl.dataset;

                    if (acreId) {
                        // if (
                        //     parseInt(acreId) === 623 ||
                        //     parseInt(acreId) === 624
                        // ) {
                        //     console.log(acreEl);
                        // }

                        const acreInt = parseInt(acreId);
                        const acreData = this.acres[acreInt];

                        if (!acreData) return;

                        const { acreType } = acreData;

                        acreEl.classList.add(`acre-type-${acreType}`);

                        if (
                            acreData.records[0].sponsorshipStatus ===
                            'Available'
                        ) {
                            acreEl.classList.add(`acre-available`);
                        } else {
                            acreEl.classList.remove(`acre-available`);
                        }

                        const { numPos } = acreData.records[0];

                        const { x, y, width, height } = acreEl.getBBox();

                        info.push({
                            number: acreId,
                            acreType,
                            position: { x, y, width, height },
                            numPos,
                        });
                    }
                });

                this.acresInfo = info;

                this.$nextTick(() => {
                    this.sizeElements();
                });
            }
        },
        mapLoaded(e) {
            e.childNodes.forEach((acreEl) => {
                if (acreEl.dataset.acreId) {
                    acreEl.addEventListener('click', this.acreClick);
                }
            });

            this.isMapLoaded = true;

            this.loadAcreInfo();
            this.zoomedAcreIdChanged();
        },
        mapClick(e) {
            const acre = e.srcElement;

            if (acre.dataset.acreId) {
                this.selectAcre(acre.dataset.acreId);

                console.log(this.selectedAcreId);
            }
        },
        downloadMap() {
            let API_BASE_URL = process.env.API_BASE_URL || '';

            if (API_BASE_URL == 'undefined') {
                API_BASE_URL = '';
            }

            window.open(`${API_BASE_URL || ''}/api/map-download`, '_blank');
        },
    },
};
</script>

<style lang="scss">
@import '../styles/main.scss';

.acres-map {
    width: 100%;
    height: 100%;

    &__controls-host {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 40px;
        top: 50%;
        transform: translateY(-50%);
        width: 60px;
        height: auto;
        background-color: #302826;
        box-shadow: 6px 6px 9px 0 rgb(0 0 0 / 30%);
        padding: 20px 0;
        grid-row-gap: 40px;
    }

    .control-button {
        opacity: 0.5;
        -webkit-transition: opacity 200ms ease;
        transition: opacity 200ms ease;
        width: 36px;
        height: 36px;
        // padding: 6px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 23px 23px;

        &:hover {
            opacity: 1;
        }
    }

    &__zoom-in-button {
        background-image: url(../assets/images/zoom-in.svg);
    }

    &__zoom-out-button {
        background-image: url(../assets/images/zoom-out.svg);
    }

    &__zoom-reset-button {
        background-image: url(../assets/images/reset-map.svg);
    }

    &__download-button {
        background-image: url(../assets/images/download.svg);
    }

    &__selected-info {
        position: absolute;
        top: 30px;
        left: 30px;
    }

    &__map-image {
        position: absolute;
        top: 0;
        left: 0;
        transform-origin: 0 0;
        width: 100%;
    }

    &__acres-svg {
        position: absolute;
        top: 0;
        left: 0;
    }

    &__map-overlay {
        position: absolute;
        top: 0;
        left: 0;
        transform-origin: 0 0;
        z-index: 1000;
        font-size: 35px;
        font-weight: 400;
        color: white;
    }

    &__acre-info {
        position: absolute;
        pointer-events: none;
        text-shadow: 2px 2px #333333;
        display: flex;
        align-items: flex-start;

        &--um {
            justify-content: center;
        }

        &--ur {
            justify-content: flex-end;
        }

        &--cl {
            align-items: center;
        }

        &--cc {
            align-items: center;
            justify-content: center;
        }

        &--cr {
            align-items: center;
            justify-content: flex-end;
        }

        &--ll {
            align-items: flex-end;
        }

        &--lm {
            align-items: flex-end;
            justify-content: center;
        }

        &--lr {
            align-items: flex-end;
            justify-content: flex-end;
        }

        span {
            padding: 5px 10px;
            margin: 3px;
        }

        &--special {
            span {
                background: $special-color;
            }
        }

        &--gp {
            span {
                background: $gp-color;
            }
        }

        &--dof {
            span {
                background: $dof-color;
            }
        }
    }

    .acre {
        // transition: opacity 300ms;
        stroke-width: 2px;

        &.acre-available {
            fill: rgba($color: green, $alpha: 0.5);
        }

        &.demoted-acre {
            opacity: 0.25;
        }

        &.zoomed-acre {
            filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.9));
            stroke-width: 4px;
        }
    }

    &__inner-container,
    &__fill-container {
        width: 100%;
        height: 100%;
        transform-origin: 50% 0;
    }

    // &__zoom-container {
    //     width: 100%;
    //     height: 100%;
    //     transform-origin: 50% 50%;
    //     transition: transform 1s;
    // }

    &__search-results {
        position: absolute;
        right: 30px;
        top: 86px;
        min-width: 400px;
        padding-top: 5px;
        padding-bottom: 10px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        background-color: #e5e1df;
        box-shadow: 6px 6px 9px 0 rgb(0 0 0 / 30%);

        h2 {
            margin: 0;
            font-size: 20px;
            letter-spacing: 0.1px;
            text-align: left;
            padding-left: 20px;
            padding-bottom: 3px;
            padding-top: 2px;
            font-weight: 300;
            border-bottom: 1px solid rgba(204, 190, 183, 0.5);
        }
    }

    &__search-last-name {
        text-align: left;
        padding-left: 20px;
        color: #b6070a;
        font-weight: 700;
        flex: 1;
    }

    &__search-acre-type {
        font-style: italic;
        flex: 1.5;
    }

    &__search-acre-number {
        padding: 2px 12px;
        background-color: $standard-color;
        font-family: Lato, sans-serif;
        color: #fff;
        font-size: 11px;
        font-weight: 700;
        text-align: center;
        letter-spacing: 3px;
        text-transform: uppercase;
        width: 28px;
        height: 20px;
        max-width: 28px;
        max-height: 20px;
        margin-right: 20px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        &--special {
            background: $special-color;
        }

        &--gp {
            background: $gp-color;
        }

        &--dof {
            background: $dof-color;
        }
    }

    &__search-results-grid {
        overflow-y: auto;
        padding-top: 10px;
    }

    &__search-result-row {
        display: flex;
        padding: 5px 0;
        font-size: 14px;
        align-items: center;

        &:first-child {
            flex: 1;
        }

        &:hover {
            background-color: #d9d4d1;
        }
    }

    &__search-host {
        position: absolute;
        top: 30px;
        right: 30px;
        display: flex;
        background-color: #302826;
        box-shadow: 3px 3px 7px 2px rgb(0 0 0 / 40%);

        .search-icon {
            background-image: url(../assets/images/search.svg);
            opacity: 0.5;
            width: 60px;
            height: 55px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 30px 30px;
        }

        input {
            margin: 0;
        }
    }

    &__search-close-button {
        position: absolute;
        left: auto;
        top: 0%;
        right: 0%;
        bottom: auto;
        padding: 6px;
        width: 24px;
        height: 24px;
        opacity: 0.5;
        -webkit-transition: opacity 200ms ease;
        transition: opacity 200ms ease;
        background-image: url(../assets/images/close.svg);
        background-size: 12px 12px;
        background-position: center;
        background-repeat: no-repeat;

        &:hover {
            opacity: 1;
        }
    }

    &__zoom-center {
        // display: none;
        position: absolute;
        background: yellow;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        transform: translate(-50%, -50%);
    }

    &__map-center {
        // display: none;
        position: absolute;
        background: purple;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        transform: translate(-50%, -50%);
    }
}
</style>
