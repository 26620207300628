<template>
    <div :class="bem()">
        <div ref="activeTransformer" :class="bem('active-transform')">
            <div
                ref="activeScale"
                :class="bem('active-transform')"
                :style="{
                    transform: activeOperationScale,
                    transformOrigin: activeOperationOrigin,
                }"
            >
                <div
                    ref="activeTranslate"
                    :class="bem('active-transform')"
                    :style="{
                        transform: activeOperationTranslate,
                    }"
                >
                    <div
                        :class="bem('scale', { 'no-animate': !animate })"
                        :style="{ transform: scaleTransform }"
                    >
                        <gesture-source
                            ref="gestureSource"
                            :class="
                                bem('translate', { 'no-animate': !animate })
                            "
                            :style="{ transform: translateTransform }"
                            @gesture="onGesture"
                        >
                            <slot />
                        </gesture-source>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div
            :class="bem('debug')"
            v-for="(p, i) in debugPoints"
            :key="i"
            :style="{
                transform: `translate(${p.x - 8}px, ${p.y - 8}px)`,
                backgroundColor: p.color,
            }"
        ></div> -->
        <!-- <button @click="test" :class="bem('test')">TEST</button> -->
    </div>
</template>

<script>
import GestureSource from './GestureSource.vue';
import { checkBounds } from '../utils/panZoomEffects';

let test_count = 0;

export default {
    name: 'zoom-container',
    components: {
        GestureSource,
    },
    props: {
        scale: { type: Number, default: 1 },
        translate: { type: Object, default: () => ({ x: 0, y: 0 }) },
        minScale: { type: Number, default: 1 },
        maxScale: { type: Number, default: 5 },
        animate: { type: Boolean, default: true },
        containerEl: { type: Object, default: null },
    },
    data() {
        return {
            transformOps: [],
            transformArray: '',
            activeOperationScale: null,
            activeOperationTranslate: null,
            activeOperationTransform: null,
            activeOperationOrigin: null,
            debugPoints: [],
        };
    },
    mounted() {
        window.tester = this;
    },
    computed: {
        scaleTransform() {
            return `scale(${this.scale})`;
        },
        translateTransform() {
            if (this.translate) {
                return `translate(${this.translate.x}px, ${this.translate.y}px)`;
            }

            return null;
        },
    },
    methods: {
        onPinch(ev) {
            // console.log(ev);

            if (ev.first) {
                // this.startPosition = { x: ev.origin[0], y: ev.origin[1] };
            } else if (ev.last) {
                this.endActiveOperation();
            } else {
                // move
                const { x: startX, y: startY } = this.startPosition;
                const x = ev.origin[0];
                const y = ev.origin[1];

                const deltaX = x - startX;
                const deltaY = y - startY;

                // scale
                const initialLength = ev.initial[0];
                const currentLength = ev.da[0];

                const scale = currentLength / initialLength;

                this.applyActiveOperation(scale, deltaX, deltaY, x, y);
            }
        },
        onGesture(ev) {
            if (ev.wheel) {
                this.wheelZoom(ev);
            } else if (ev.pan) {
                this.pan(ev);
            } else if (ev.zoom) {
                this.zoom(ev);
            }
        },
        zoom(gestureInfo) {
            const { scale, deltaX, deltaY, originX, originY } = gestureInfo;

            if (gestureInfo.first) {
                // this.startPosition = { x: ev.origin[0], y: ev.origin[1] };
            } else if (gestureInfo.last) {
                this.endActiveOperation();
            } else {
                // move
                // const { x: startX, y: startY } = this.startPosition;
                // const x = ev.origin[0];
                // const y = ev.origin[1];

                // const deltaX = x - startX;
                // const deltaY = y - startY;

                // // scale
                // const initialLength = ev.initial[0];
                // const currentLength = ev.da[0];

                // const scale = currentLength / initialLength;

                this.applyActiveOperation(
                    scale,
                    deltaX,
                    deltaY,
                    originX,
                    originY
                );
            }
        },
        pan(gestureInfo) {
            const { deltaX, deltaY } = gestureInfo;

            if (gestureInfo.last) {
                this.endActiveOperation();
            } else {
                this.applyActiveOperation(1, deltaX, deltaY);
            }
        },
        wheelZoom(gestureInfo) {
            const { scale, originX, originY } = gestureInfo;

            this.applyTranform({
                scale,
                originX,
                originY,
                operation: 'wheel',
            });
        },
        onDrag(ev) {
            const [deltaX, deltaY] = ev.movement;

            if (ev.last) {
                this.endActiveOperation();
            } else {
                this.applyActiveOperation(1, deltaX, deltaY);
            }
        },
        applyActiveOperation(scale, x, y, originX = 0, originY = 0) {
            if (this.containerEl) {
                const containerBounds =
                    this.containerEl.getBoundingClientRect();

                const containerHalfWidth = containerBounds.width / 2;
                const containerHalfHeight = containerBounds.height / 2;

                const bounded = checkBounds({
                    x: x + this.translate.x,
                    y: y + this.translate.y,
                    minX: -containerHalfWidth * scale,
                    minY: -containerHalfHeight * scale,
                    maxX: containerHalfWidth * scale,
                    maxY: containerHalfHeight * scale,
                });

                // console.log(bounded);

                const { inBounds, restrictedX, restrictedY } = bounded;

                // TODO: adjustments need to take scale into account

                if (!inBounds) {
                    if (restrictedX !== undefined) {
                        x = restrictedX - this.translate.x;
                    }
                    if (restrictedY !== undefined) {
                        y = restrictedY - this.translate.y;
                    }
                }
            }

            // this.activeOperationTransform = `translate(${x}px, ${y}px) scale(${scale})`;
            this.activeOperationTranslate = `translate(${x}px, ${y}px)`;
            this.activeOperationScale = `scale(${scale})`;

            if (originX && originY) {
                this.activeOperationOrigin = `${originX}px ${originY}px`;
            } else {
                this.activeOperationOrigin = '';
            }
        },
        endActiveOperation() {
            const { transform: translateTransform } = window.getComputedStyle(
                this.$refs.activeTranslate
            );
            const { transform: scaleTransform, transformOrigin: scaleOrigin } =
                window.getComputedStyle(this.$refs.activeScale);

            const scaleMatrix = new DOMMatrix(scaleTransform);
            const scale = scaleMatrix.a;

            const translateMatrix = new DOMMatrix(translateTransform);
            const x = translateMatrix.e;
            const y = translateMatrix.f;

            const transOrigin = scaleOrigin.split(' ');
            const transOriginX = parseFloat(transOrigin[0]);
            const transOriginY = parseFloat(transOrigin[1]);

            this.activeOperationTranslate = '';
            this.activeOperationScale = '';
            this.activeOperationOrigin = '';

            this.applyTranform({
                scale,
                originX: transOriginX,
                originY: transOriginY,
                translateX: x,
                translateY: y,
            });
        },
        test() {
            const boundingRect =
                this.$refs.activeTransformer.getBoundingClientRect();

            const centerX = boundingRect.width / 2;
            const centerY = boundingRect.height / 2;

            if (test_count === 0) {
                this.applyActiveOperation(
                    1.5,
                    300,
                    400,
                    centerX + 200,
                    centerY - 300
                );
            } else {
                this.applyActiveOperation(
                    1.5,
                    -800,
                    300,
                    centerX - 50,
                    centerY + 500
                );
            }

            test_count++;

            setTimeout(() => {
                this.endActiveOperation();
            }, 2000);
        },
        applyTranform({
            scale,
            originX,
            originY,
            translateX,
            translateY,
            operation,
        }) {
            let newScale = this.scale * scale;
            const clampedScale = this.clampScale(newScale);

            if (clampedScale !== newScale) {
                newScale = clampedScale;

                scale = clampedScale / this.scale;
            }

            const boundingRect =
                this.$refs.activeTransformer.getBoundingClientRect();

            const outerScale =
                boundingRect.width / this.$refs.activeTransformer.offsetWidth;

            const centerX = boundingRect.width / 2 / outerScale;
            const centerY = boundingRect.height / 2 / outerScale;

            this.debugPoints.push({
                x: originX + boundingRect.x,
                y: originY + boundingRect.y,
                color: 'red',
            });

            // get the coordinates offset from center of the zoomer
            const transCenterOffsetX = originX - centerX;
            const transCenterOffsetY = originY - centerY;

            // scale those coordinates based on the applied scale
            const scaledOffsetX = transCenterOffsetX * scale;
            const scaledOffsetY = transCenterOffsetY * scale;

            // console.log(outerScale, scaledOffsetX);

            // this.debugPoints.push({
            //     x: scaledOffsetX + centerX - boundingRect.x,
            //     y: scaledOffsetY + centerY - boundingRect.y,
            //     color: 'green',
            // });

            this.logDebugPoints();

            // get the difference between the transform origin and the scaled origin
            const applyOffsetX = scaledOffsetX - transCenterOffsetX;
            const applyOffsetY = scaledOffsetY - transCenterOffsetY;

            // this.logPoint(-applyOffsetX, -applyOffsetY);

            // console.log(applyOffsetX, applyOffsetY);

            const newTranslate = { ...this.translate };
            newTranslate.x -= applyOffsetX / newScale;
            newTranslate.y -= applyOffsetY / newScale;

            // also add any direct translation
            if (translateX || translateY) {
                newTranslate.x += (translateX || 0) / this.scale;
                newTranslate.y += (translateY || 0) / this.scale;
            }

            if (Number.isNaN(newTranslate.x) || Number.isNaN(newTranslate.y)) {
                // eslint-disable-next-line no-debugger
                debugger;
            }

            if (
                newScale !== this.scale ||
                this.translate.x !== newTranslate.x ||
                this.translate.y !== newTranslate.y
            ) {
                this.$emit('update:scale', this.clampScale(newScale));
                this.$emit('update:translate', newTranslate);

                this.$emit('updateTransform', {
                    scale: this.clampScale(newScale),
                    translate: newTranslate,
                    operation,
                });
            }
        },
        logDebugPoints() {
            // this.debugPoints.forEach((p) => {
            //     console.log(`x: ${p.x}, y: ${p.y}, color: ${p.color}`);
            // });
        },
        logPoint(x, y) {
            console.log(`(${x}, ${y})`);
        },
        mouseWheelZoom(ev) {
            const { deltaY, clientX: pageX, clientY: pageY } = ev;
            const step = deltaY / 175;

            const zoomChange = step * 0.2;

            this.applyTranform({
                scale: 1 + zoomChange,
                originX: pageX,
                originY: pageY,
                operation: 'wheel',
            });
        },
        clampScale(scale) {
            return Math.min(this.maxScale, Math.max(this.minScale, scale));
        },
    },
};
</script>

<style lang="scss">
.zoom-container {
    width: 100%;
    height: 100%;
    transform-origin: 0 0; // 50% 50%;
    touch-action: none;
    // transition: transform 1s;

    &__translate,
    &__scale {
        position: absolute;
        width: 100%;
        height: 100%;
        transform-origin: 50% 50%;
        transition: transform 0.5s ease-in-out;

        &--no-animate {
            transition: none;
        }
    }

    &__active-transform {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: transparent;
        touch-action: none;
    }

    &__debug {
        position: absolute;
        top: 0;
        left: 0;
        width: 16px;
        height: 16px;
        border-radius: 8px;
    }

    &__test {
        position: absolute;
        top: 0;
        left: 50%;
        z-index: 100000;
    }
}
</style>
