import { createRouter, createWebHistory } from 'vue-router';
import MapView from '../views/MapView.vue';

const routes = [
    {
        path: '/',
        name: 'map',
        component: MapView,
    },
    {
        path: '/login',
        name: 'login',
        // route level code-splitting
        // this generates a separate chunk (login.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
