<template>
    <div @mousewheel="mouseWheelZoom" @pointerdown="operationStart">
        <slot />
    </div>
</template>

<script>
let passiveSupported;

function getPassiveSupported() {
    if (passiveSupported !== undefined) {
        return passiveSupported;
    }

    try {
        const options = Object.defineProperty({}, 'passive', {
            get() {
                passiveSupported = true;

                return passiveSupported;
            },
        });

        window.addEventListener('test', null, options);
        // eslint-disable-next-line no-empty
    } catch (err) {}

    return passiveSupported;
}

function centerPoint(p1, p2) {
    const diff = pointDiff(p1, p2); // { x: p2.x - p1.x, y: p2.y - p1.y };

    return { x: p1.x + diff.x / 2, y: p1.y + diff.y / 2 };
}

function pointDiff(p1, p2) {
    return { x: p2.x - p1.x, y: p2.y - p1.y };
}

function vec2Len(v) {
    return Math.sqrt(v.x * v.x + v.y * v.y);
}

export default {
    data() {
        return {
            activePointers: { count: 0, pointers: [], first: null },
            startPositions: {},
            currentPositions: {},
            lastPositions: {},
            docHandlersAdded: false,
            lastEventInfo: null,
            // ANIMATION RELATED PROPS
            trackingPoints: [],
        };
    },
    methods: {
        raiseGesture(info) {
            const args = { ...info };

            // console.log('sending gesture with args', args);

            this.$emit('gesture', args);
        },
        mouseWheelZoom(ev) {
            const { deltaY, clientX: x, clientY: y } = ev;
            const step = deltaY / 175;

            const zoomChange = step * 0.2;

            this.raiseGesture({
                scale: 1 - zoomChange,
                originX: x,
                originY: y,
                deltaX: 0,
                deltaY: 0,
                wheel: true,
            });
        },
        operationStart(ev) {
            // only 2 pointers allowed
            if (this.activePointers.count >= 2) {
                return;
            }

            // operationStart fires on pointer down. any time this occurs a new operation is effectively
            // starting, even if an operation is currently in process. so, a couple things can happen:
            //
            // 1)   If no pointers are already active the pointer that triggered this event will set its
            //      start, current and last positions to the position of the event.
            // 2)   If there is 1 active pointer (1st) and this is a second active pointer (2nd) then
            //      the start, current and last positions of the 1st pointer are set to its current
            //      position since that is the position it is in for the start of this new operation.
            //      The 2nd pointer sets it's positions as in (1) as if it were the only pointer

            // if already 1 pointer set its positions
            if (this.activePointers.count === 1) {
                // end the current op
                this.sendPan(true);

                const pKey = this.activePointers.first;
                const current = this.currentPositions[pKey];

                this.startPositions[pKey] = current;
                this.lastPositions[pKey] = current;
            }

            // set positions for the new pointer
            const event = this.normalizeEvent(ev);

            this.addActivePointer(event);

            const { x, y } = event;
            const position = { x, y };

            this.startPositions[event.pointerKey] = position;
            this.currentPositions[event.pointerKey] = position;
            this.lastPositions[event.pointerKey] = position;

            // add document handlers if needed
            if (!this.docHandlersAdded) {
                this.docHandlersAdded = true;

                document.addEventListener(
                    'pointermove',
                    this.operationMove,
                    getPassiveSupported() ? { passive: false } : false
                );
                document.addEventListener(
                    'pointerup',
                    this.operationEnd,
                    getPassiveSupported() ? { passive: false } : false
                );
                document.addEventListener(
                    'pointercancel',
                    this.operationEnd,
                    getPassiveSupported() ? { passive: false } : false
                );
            }
        },
        operationMove(ev) {
            ev.preventDefault();

            if (this.activePointers.count > 0) {
                const event = this.normalizeEvent(ev);

                if (!this.activePointers[event.pointerId]) {
                    // this pointer doesn't belong to us
                    return;
                }

                this.updatePointer(event);

                const { x, y } = this.activePointers[event.pointerKey];

                this.currentPositions[event.pointerKey] = { x, y };

                // todo: add tracking points correctly once animating decel
                // this.addTrackingPoint(this.pointerLastX, this.pointerLastY);
                this.requestTick();
            }
        },
        operationEnd(ev) {
            const event = this.normalizeEvent(ev);

            if (!this.activePointers[event.pointerId]) {
                // this pointer doesn't belong to us
                return;
            }

            // operationEnd fires on pointer up. any time this occurs an operation is effectively
            // ending, even if another operation will. so, a couple things can happen:
            //
            // 1)   If no pointers are are active after removing the last pointer then nothing more
            //      needs to be done here and the decel animation will run.
            // 2)   If there is 1 active pointer (1st) left then we're going from a zoom to a pan
            //      so reset the start position for that remaining pointer
            if (this.activePointers.count === 2) {
                // end the current op
                this.sendZoom(true);
            } else if (this.activePointers.count === 1) {
                // this will be the last gesture event
                this.lastEventInfo = this.getPanInfo(true);
            }

            this.stopTracking(event);

            if (this.activePointers.count === 1) {
                const pKey = this.activePointers.first;
                const current = this.currentPositions[pKey];

                this.startPositions[pKey] = current;
                this.lastPositions[pKey] = current;
            }
        },
        updatePointer(pointerInfo) {
            const { pointerKey } = pointerInfo;

            if (this.activePointers[pointerKey]) {
                this.activePointers[pointerKey] = pointerInfo;
            }
        },
        addActivePointer(pointerInfo) {
            const { pointerKey } = pointerInfo;

            this.activePointers[pointerKey] = pointerInfo;

            this.activePointers.pointers.push(pointerKey);
            this.activePointers.count = this.activePointers.pointers.length;

            if (!this.activePointers.first) {
                this.activePointers.first = pointerKey;
            }
        },
        removeActivePointer(pointerInfo) {
            const { pointerKey } = pointerInfo;

            delete this.activePointers[pointerKey];

            const pointerIdx = this.activePointers.pointers.findIndex(
                (pk) => pk === pointerKey
            );

            this.activePointers.pointers.splice(pointerIdx, 1);

            if (this.activePointers.first === pointerKey) {
                this.activePointers.first = null;
            }

            this.activePointers.count = this.activePointers.pointers.length;

            if (!this.activePointers.first && this.activePointers.count === 1) {
                this.activePointers.first = this.activePointers.pointers[0];
            }
        },
        normalizeEvent(ev) {
            let pointerId =
                ev.pointers && ev.pointers.length === 1
                    ? ev.pointers[0].id
                    : ev.pointerId;
            let { clientX, clientY } = ev;

            if (!pointerId && ev.changedTouches) {
                const touch = ev.changedTouches[0];

                pointerId = touch.identifier;
                clientX = touch.clientX;
                clientY = touch.clientY;
            }

            return {
                x: clientX,
                y: clientY,
                pointerId,
                pointerKey: pointerId.toString(),
            };
        },
        stopTracking(ev) {
            this.removeActivePointer(ev);

            if (this.activePointers.count === 0) {
                this.docHandlersAdded = false;

                document.removeEventListener(
                    'pointermove',
                    this.manipulateMove,
                    getPassiveSupported() ? { passive: false } : false
                );
                document.removeEventListener(
                    'pointerup',
                    this.manipulateEnd,
                    getPassiveSupported() ? { passive: false } : false
                );
                document.removeEventListener(
                    'pointercancel',
                    this.manipulateEnd,
                    getPassiveSupported() ? { passive: false } : false
                );

                this.addTrackingPoint(this.pointerLastX, this.pointerLastY);
                this.startDecelAnim();
            }
        },
        getPanInfo(endEvent) {
            const pointerKey = this.activePointers.pointers[0];
            const current = this.currentPositions[pointerKey];
            const last = this.lastPositions[pointerKey];
            const start = this.startPositions[pointerKey];

            const lastDeltaX = current.x - last.x;
            const lastDeltaY = current.y - last.y;

            const deltaX = current.x - start.x;
            const deltaY = current.y - start.y;

            this.lastPositions[pointerKey] = current;

            return {
                scale: 1,
                deltaX,
                deltaY,
                lastDeltaX,
                lastDeltaY,
                pan: true,
                last: endEvent,
            };
        },
        sendPan(endEvent) {
            this.raiseGesture(this.getPanInfo(endEvent));
        },
        sendZoom(endEvent) {
            const key1 = this.activePointers.pointers[0];
            const key2 = this.activePointers.pointers[1];

            const current1 = this.currentPositions[key1];
            const last1 = this.lastPositions[key1];
            const start1 = this.startPositions[key1];
            const current2 = this.currentPositions[key2];
            const last2 = this.lastPositions[key2];
            const start2 = this.startPositions[key2];

            // calculate movement deltas
            const startCenter = centerPoint(start1, start2);
            const currentCenter = centerPoint(current1, current2);
            const lastCenter = centerPoint(last1, last2);

            const lastDeltaX = currentCenter.x - lastCenter.x;
            const lastDeltaY = currentCenter.y - lastCenter.y;

            const deltaX = currentCenter.x - startCenter.x;
            const deltaY = currentCenter.y - startCenter.y;

            // calculate zoom deltas
            const startLength = vec2Len(pointDiff(start1, start2));
            const currentLength = vec2Len(pointDiff(current1, current2));
            const lastLength = vec2Len(pointDiff(last1, last2));

            const scale = currentLength / startLength;
            const lastScale = currentLength / lastLength;

            this.lastPositions[key1] = current1;
            this.lastPositions[key2] = current2;

            this.raiseGesture({
                scale,
                lastScale,
                deltaX,
                deltaY,
                lastDeltaX,
                lastDeltaY,
                originX: currentCenter.x,
                originY: currentCenter.y,
                zoom: true,
                last: endEvent,
            });
        },
        // ANIMATE RELATED METHODS
        requestTick() {
            if (!this.ticking) {
                requestAnimationFrame(this.update);
            }
            this.ticking = true;
        },
        startDecelAnim() {
            this.requestTick();
        },
        update() {
            if (this.activePointers.count === 1) {
                this.sendPan(false);
            } else if (this.activePointers.count === 2) {
                this.sendZoom(false);
            } else if (this.lastEventInfo) {
                this.raiseGesture(this.lastEventInfo);

                this.lastEventInfo = null;
            }

            this.ticking = false;
        },
        addTrackingPoint(x, y) {
            const time = Date.now();
            while (this.trackingPoints.length > 0) {
                if (time - this.trackingPoints[0].time <= 100) {
                    break;
                }
                this.trackingPoints.shift();
            }

            this.trackingPoints.push({ x, y, time });
        },
    },
};
</script>

<style></style>
