<template>
    <div :class="bem()">
        <div :class="bem('map-container')">
            <acres-map />
        </div>
        <acre-dialog v-model="showAcreInfo" :acre="selectedAcre" />
    </div>
</template>

<script>
import { mapState } from 'pinia';
import { mapActions } from 'pinia';
import { useMainStore } from '@/stores/mainStore.js';
import AcresMap from '@/components/AcresMap.vue';
import AcreDialog from '@/components/AcreDialog.vue';

export default {
    name: 'map-view',
    components: {
        AcresMap,
        AcreDialog,
    },
    computed: {
        ...mapState(useMainStore, ['selectedAcreId', 'selectedAcre']),
        showAcreInfo: {
            get() {
                return !!this.selectedAcreId;
            },
            set(val) {
                if (!val) {
                    this.selectAcre(null);
                }
            },
        },
    },
    methods: {
        ...mapActions(useMainStore, ['selectAcre']),
    },
};
</script>

<style lang="scss">
.map-view {
    width: 100%;
    height: 100%;

    &__map-container {
        width: 100%;
        height: 100%;
        background-image: url(../assets/images/bg-parchment-tile.jpg);
        background-size: 362.5px 362.5px;
    }
}
</style>
